 /*  $(document).ready(function () {
    md = {
        misc: {
            navbar_menu_visible: 0,
            active_collapse: !0,
            disabled_collapse_init: 0
        }
    }
     $("#minimizeSidebar").click(function () {
        $(this);
        1 == md.misc.sidebar_mini_active ? ($("body").removeClass("sidebar-mini"), md.misc.sidebar_mini_active = !1) : ($("body").addClass("sidebar-mini"), md.misc.sidebar_mini_active = !0);
        var e = setInterval(function () {
            window.dispatchEvent(new Event("resize"))
        }, 180);
        setTimeout(function () {
            clearInterval(e)
        }, 1e3)
    });
 
   
     //   $sidebar = $(".sidebar"), image_src = $sidebar.data("image"), void 0 !== image_src && (sidebar_container = '<div class="sidebar-background" style="background-image: url(' + image_src + ') "/>', $sidebar.append(sidebar_container))
    
});

   */